import {Component} from "react";
import {logsStores} from "../../stores/LogsStores";
import {observer} from "mobx-react";

@observer
export default class TableContent extends Component<any, any> {

    componentDidMount() {
        logsStores.loadLogs();
    }

    formatDt(v: string) {
        let d = new Date(v);
        return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
    }

    render() {
        return <div>
            <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Время</th>
                    <th scope="col">IP</th>
                    <th scope="col">Домен</th>
                    <th scope="col">Адрес страницы, на которой возникла ошибка</th>
                    <th scope="col">Браузер</th>
                    <th scope="col">Сообщение об ошибке</th>
                </tr>
                </thead>
                <tbody>
                {logsStores.logs.map((i, idx) =>
                    <tr
                        key={`data-${idx}`}
                        onClick={() => logsStores.setSelectedId(i._id)}
                        style={{cursor: 'pointer'}}
                    >
                        <th scope="row">{i._id}</th>
                        <td>{this.formatDt(i.dt)}</td>
                        <td>{i.ip}</td>
                        <td>{i.location_hostname}</td>
                        <td>
                            <a href={i.location_href}>
                                {i.location_href}
                            </a>
                        </td>
                        <td>{i.user_agent}</td>
                        <td>{i.error_message}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>;
    }
}