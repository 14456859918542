import {Component} from "react";

export default class AboutComponent extends Component<any, any> {
    render() {
        let url = `${document.location.origin}/cdn/Logger.js?v=13`;
        return (<div>
                <span>Скрипт для добавления на страницу: </span>
                <code>
                    {`<script src="${url}"></script>`}
                </code>
            </div>
        );
    }
}