import {Component} from "react";
import Filters from "./components/filters/Filters";
import {observer} from "mobx-react";
import TableContent from "./components/table/TableContent";
import ViewStack from "./components/viewStack/ViewStack";
import AboutComponent from "./components/about/AboutComponent";

@observer
export default class App extends Component<any, any> {

    render() {
        return (
            <div>
                <AboutComponent/>
                <Filters/>
                <TableContent/>
                <ViewStack/>
            </div>
        );
    }
}