import {action, makeAutoObservable, observable} from "mobx";

type TOriginalData = {
    line:number,
    column: number,
    source: string
};

class BundleToolStore {

    @observable urlForGet = '';
    @observable gettedData?: TOriginalData;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setUrlForGet(v: string) {
        this.urlForGet = v;
    }

    @action
    setGettedData(v?: TOriginalData) {
        this.gettedData = v;
    }

    async getData() {
        this.setGettedData(undefined);
        let response = await fetch('/api/tools/getOriginalLineColFromBundle', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: this.urlForGet
            })
        });
        if(response.ok) {
            let data = await response.json();
            this.setGettedData(data);
        }
    }
}

export const bundleToolStore = new BundleToolStore();