import {action, makeAutoObservable, observable} from "mobx";
import {logsStores} from "./LogsStores";

class FiltersStore {

    @observable domains: Array<string> = [];

    @observable domain:string | undefined;
    @observable from: string = `${this.getCurrentDate()} 00:00:00`;
    @observable to: string = `${this.getCurrentDate()} 23:59:59`;

    constructor() {
        makeAutoObservable(this);
        this.loadDomains();
    }


    @action
    setFrom(v: string) {
        this.from = v;
        this.loadLogs();
    }

    @action
    setTo(v: string) {
        this.to = v;
        this.loadLogs();
    }

    @action
    setDomains(v: Array<string>) {
        this.domains = v;
    }

    @action
    setDomain(v: string | undefined) {
        this.domain = v;
        this.loadLogs();
    }

    getCurrentDate() {
        let d = new Date();
        let day = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        return `${year}-${this.withZero(month)}-${this.withZero(day)}`;
    }

    withZero(v: string | number) {
        return `${v}`.length === 1 ? `0${v}` : v;
    }

    loadLogs() {
        logsStores.loadLogs();
    }

    getParams() {
        let params:{[key: string]: string} = {
            from: new Date(this.from).toISOString(),
            to: new Date(this.to).toISOString()
        };

        if(this.domain) {
            params['domain'] = this.domain;
        }

        return params;
    }

    async loadDomains() {
        let response = await fetch('/api/logs/domains');
        let data = await response.json();
        this.setDomains(data);
    }
}

export const filtersStore = new FiltersStore();