import {Component} from "react";
import {logsStores} from "../../stores/LogsStores";
import {observer} from "mobx-react";
import OriginalGetter from "./OriginalGetter";

@observer
export default class ViewStack extends Component<any, any> {
    render() {
        return logsStores.selectedLog &&
            <div className={'m-1'}>
                <div className={'bg-secondary d-flex justify-content-between border border-dark'}>
                    <div className={'p-1'}>{logsStores.selectedLog.error_message}</div>
                    <div
                        className={'p-1'}
                        style={{cursor: "pointer"}}
                        onClick={() => logsStores.setSelectedId(undefined)}
                    >X
                    </div>
                </div>
                <div className={'p-1'}>
                    <code style={{whiteSpace: 'pre-wrap'}}>
                        {logsStores.selectedLog.error_stack}
                    </code>
                </div>
                <div className={'p-1'}>
                    <OriginalGetter/>
                </div>
            </div>
            ;
    }
}