import {action, makeAutoObservable, observable} from "mobx";
import {TLog} from "../entity/TLog";
import {filtersStore} from "./FiltersStore";

class LogsStores {

    @observable logs: Array<TLog> = [];

    @observable selectedId?: string;
    @observable selectedLog?: TLog;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setLogs(v: Array<TLog>) {
        this.logs = v;
    }

    @action
    setSelectedId(v?: string) {
        this.selectedId = v;
        this.selectedLog = undefined;
        this.loadSelectedId();
    }

    @action
    setSelectedLog(v: TLog) {
        this.selectedLog = v;
    }

    async loadLogs() {
        let params = filtersStore.getParams();
        let filters = [];
        for(let key in params) {
            filters.push(`${key}=${params[key]}`);
        }
        let response = await fetch(`/api/logs/?${filters.join('&')}`);
        let data = await response.json();
        this.setLogs(data);
    }

    async loadSelectedId() {
        if (this.selectedId) {
            let response = await fetch(`/api/logs/${this.selectedId}`);
            let data = await response.json();
            this.setSelectedLog(data);
        }
    }
}

export const logsStores = new LogsStores();