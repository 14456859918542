import {Component} from "react";
import {observer} from "mobx-react";
import {bundleToolStore} from "../../stores/BundleToolStore";

@observer
export default class OriginalGetter extends Component<any, any> {

    render() {
        return <div>
            <div>
                <span>Запрос ссылки на оригинал: </span>
                <span>
                    <input
                        type={'text'}
                        value={bundleToolStore.urlForGet}
                        onChange={e => bundleToolStore.setUrlForGet(e.target.value)}
                    />
                </span>
                <span>
                    <button
                        onClick={() => bundleToolStore.getData()}
                    >
                        Запросить
                    </button>
                </span>
            </div>
            {bundleToolStore.gettedData &&
            <div>
                {`${bundleToolStore.gettedData.source}:${bundleToolStore.gettedData.line}:${bundleToolStore.gettedData.column}`}
            </div>
            }
        </div>
            ;
    }
}