import {Component} from "react";
import {observer} from "mobx-react";
import {filtersStore} from "../../stores/FiltersStore";

@observer
export default class Filters extends Component<any, any> {

    render() {
        return (
            <div className={'d-flex m-1'}>
                <span className={'p-1'}>
                    С
                </span>

                <span className={'p-1'}>
                    <input
                        type={'datetime-local'}
                        value={filtersStore.from}
                        onChange={e => filtersStore.setFrom(e.target.value)}
                    />
                </span>

                <span className={'p-1'}>
                    по
                </span>
                <span className={'p-1'}>
                    <input
                        type={'datetime-local'}
                        value={filtersStore.to}
                        onChange={e => filtersStore.setTo(e.target.value)}
                    />
                </span>

                <span className={'p-1'}>
                    Домен
                </span>
                <span className="p-1">
                    <select
                        onChange={e => filtersStore.setDomain(e.target.value)}
                    >
                        <option value={''}>Не выбран</option>
                        {filtersStore.domains.map((d, idx) =>
                            <option
                                value={d}
                                key={`domain-select-${idx}`}
                            >
                                {d}
                            </option>
                        )}
                    </select>
                </span>
            </div>
        );
    }
}